"use client";

import { ReloadIcon } from "@radix-ui/react-icons";
import { Loader2Icon } from "lucide-react";
import { useEffect, useTransition } from "react";
import * as Sentry from "@sentry/nextjs";
import { Button } from "@/components/ui/button";
import { useRouter } from "next/navigation";
export default function ErrorScreen({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const handleReload = () => {
    startTransition(() => {
      router.refresh();
    });
  };
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <div className="flex h-screen flex-col items-center justify-center gap-6" data-sentry-component="ErrorScreen" data-sentry-source-file="error-screen.tsx">
      <div className="flex flex-col items-center gap-1">
        <h1 className="text-3xl">Oooops...</h1>
        <p className="text-foreground/80">
          Something went wrong. Please try again.
        </p>
      </div>

      <Button onClick={handleReload} disabled={isPending} data-sentry-element="Button" data-sentry-source-file="error-screen.tsx">
        Reload{isPending ? "ing" : ""}
        {isPending ? <Loader2Icon className="animate-spin" /> : <ReloadIcon />}
      </Button>
    </div>;
}